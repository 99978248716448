export const MAX_DATE_INPUT_LENGTH = 10;
export const DATE_CELLS_COUNT = 42;
export const DATE_CELLS_IN_ROW_COUNT = 7;
export const PERIOD_CELLS_IN_ROW_COUNT = 4;
export const LEFT_PERIOD_PANEL_START_INDEX = 0;
export const RIGHT_MONTH_PANEL_START_INDEX = 12;
export const RIGHT_YEAR_PANEL_START_INDEX = 10;
export const YEARS_IN_DECADE = 10;
export const LAST_MONTH_IN_YEAR_INDEX = 11;
export const LAST_YEAR_IN_DECADES_RANGE = 9;
export const DAYS_IN_WEEK = 7;
export const MONTHS_COUNT = 12;
export const CELLS_COUNT_IN_YEAR_RANGE = 20;
export const WEEK_FRONTIER = 3;
