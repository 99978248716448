export const UPDATE_MODEL_VALUE_EVENT = 'update:modelValue';
export const CLICK_EVENT = 'click';
export const CHANGE_EVENT = 'change';
export const INPUT_EVENT = 'input';
export const FOCUS_EVENT = 'focus';
export const BLUR_EVENT = 'blur';
export const CLEAR_EVENT = 'clear';
export const CLEAR_ALL_EVENT = 'clear-all';
export const CLOSE_EVENT = 'close';
export const REMOVE_EVENT = 'remove';
export const ABORT_EVENT = 'abort';
export const SELECT_EVENT = 'select';
export const EXCEED_EVENT = 'exceed';
export const SELECT_ALL_EVENT = 'select-all';
